import React, { useEffect } from 'react';
import s from './Popup.module.scss';
import { CONSULT_URL } from 'Constants';

interface IProps {
  open: boolean;
  message: string;
  onClose: () => void;
}

const UpdatePlanPopup = (props: IProps) => {
 
  const handleClose = () => {
    props.onClose();
  };

  useEffect(() => {
    window.addEventListener('message', (event) => {
      const data = event.data;
      if (data.status && data.status == 'close') {
        handleClose();
      }
    })

  }, [])


  const iframeSrc = `${CONSULT_URL}/console/frame/update-plan?message=${encodeURIComponent(props.message)}`;
  return props.open ?
    <div className={s.frameModalPayment} onClick={() => handleClose()}>
      <iframe
        src={iframeSrc}
        width="100%"
        height="100%"
        className={s.frame}
        allowTransparency={true}
        style={{backgroundColor:'transparent'}}
      ></iframe>
    </div> : null
};

export default UpdatePlanPopup;
